// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import { Checkbox } from '@mui/material';
import MDInput from "components/MDInput";
import * as service from '../../../services/service';

function SubAssemblyMaterialMap() {
    const [data, setData] = useState([]);
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [materialCategoryData, setMaterialCategoryData] = useState([]);
    const [materialSubCategoryData, setMaterialSubCategoryData] = useState([]);
    const [materialCategoryId, setMaterialCategoryId] = useState('');
    const [materialSubCategoryId, setMaterialSubCategoryId] = useState('');
    const [materialIds, setMaterialIds] = useState([]);
    const [quantity, setQuantity] = useState([]);
    const [isSelected, setIsSelected] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    // const navigate = useNavigate();
    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };
    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };

    const getSubAssemblies = (prodId) => {
        fetch(
            service.SUBASSEMBLY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prodId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subAssemblyId, label: item.subAssembly }));
                setSubAssemblyData(data2);
            });
    };

    const getParts = (assemblyId) => {
        fetch(
            service.PART_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: assemblyId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.partId, label: item.part }));
                const all = ({ value: "0", label: "Select Part" });
                data2.unshift(all);
                setPartData(data2);
                setPartId(all);
            });
    };

    const getMaterialCategories = () => {
        fetch(
            service.MATERIAL_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialCategoryId, label: item.materialCategory }));
                setMaterialCategoryData(data2);
            });
    };
    const getMaterialSubCategories = (id) => {
        fetch(
            service.MATERIAL_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    materialCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialSubCategoryId, label: item.materialSubCategory }));
                setMaterialSubCategoryData(data2);
            });
    };
    const getDetails = (id) => {
        if (id > 0) {
            fetch(
                service.GET_SUBASSEMBLY_MATERIAL_MAP,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SubAssemblyId: subAssemblyId.value,
                        PartId: partId.value,
                        MaterialCategoryId: materialCategoryId.value,
                        MaterialSubCategoryId: id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                    result.forEach((element, index) => {
                        materialIds[index] = element.materialId;
                        setMaterialIds([...materialIds]);
                        isSelected[index] = element.isSelected;
                        setIsSelected([...isSelected]);
                        quantity[index] = element.quantity;
                        setQuantity([...quantity]);
                    });
                })
                .catch(err => {
                    if (err) {
                        setErrors(err);
                    };
                });
        }
    };
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!productCatId) {
            isValid = false;
            errs[".productCategory"] = "Please select product category";
        }
        if (!productSubCatId) {
            isValid = false;
            errs[".productSubcategory"] = "Please select product sub category";
        }
        if (!productId) {
            isValid = false;
            errs[".product"] = "Please select product";
        }
        if (!subAssemblyId) {
            isValid = false;
            errs[".subAssemblyId"] = "Please select sub assembly";
        }
        if (!materialCategoryId) {
            isValid = false;
            errs[".materialCategoryId"] = "Please select material category";
        }
        if (!materialSubCategoryId) {
            isValid = false;
            errs[".materialSubCategoryId"] = "Please select material sub category";
        }
        if (!isSelected.includes(true)) {
            isValid = false;
            errs[".materialId"] = "Please select material";
        }
        setErrors(errs);
        return isValid;
    }
    const submitHandler = e => {
        if (validate()) {
            // eslint-disable-next-line  
            console.log(materialIds);    // do not remove  
            e.preventDefault();
            const errs = {};
            setLoading(true);
            let flag = true;
            fetch(
                service.DELETE_SUBASSEMBLY_MATERIAL_MAP,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SubAssemblyId: subAssemblyId.value,
                        PartId: partId.value,
                        MaterialCategoryId: materialCategoryId.value,
                        MaterialSubCategoryId: materialSubCategoryId.value
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/masters/subassemblymaterialmap');
                    }
                    else if (result.messageType === 'Success') {
                        materialIds.forEach((element, index) => {
                            if (isSelected[index] === true) {
                                fetch(
                                    service.ADD_SUBASSEMBLY_MATERIAL_MAP,
                                    {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            SubAssemblyId: subAssemblyId.value,
                                            PartId: partId.value,
                                            MaterialId: element,
                                            Quantity: quantity[index],
                                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId
                                        })
                                    })
                                    .then(resp => resp.json())
                                    .then(respo => {
                                        if (respo.messageType === 'Failed') {
                                            flag = false;
                                            // eslint-disable-next-line
                                            alert(respo.message);
                                            // navigate('/masters/subassemblymaterialmap');
                                        }
                                        else {
                                            flag = false;
                                            errs[".invalid"] = respo.message.message;
                                            setErrors(errs);
                                        }
                                    });
                            }
                        });

                        if (flag) {
                            // eslint-disable-next-line
                            alert("Data Saved Successfully");
                            // navigate('/masters/operationmachinemap');
                            window.location.reload(false);
                        }
                    }
                });

            setLoading(false);
        }
    }

    const productCategoryChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setMaterialSubCategoryId(null);
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
        getProductSubCategories(ev.value);
    }
    const productSubCategoryChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        setSubAssemblyId(null);
        setMaterialSubCategoryId(null);
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
        setProductId(ev);
        getSubAssemblies(ev.value);
    }
    const subAssemblyChangeHandler = (ev) => {
        setSubAssemblyId(ev);
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
        getParts(ev.value);
    }
    const partChangeHandler = (ev) => {
        setPartId(ev);
        setMaterialSubCategoryId(null);
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
    }
    const materialCategoryChangeHandler = (ev) => {
        setMaterialCategoryId(ev);
        setMaterialSubCategoryId(null);
        getMaterialSubCategories(ev.value);
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
    }
    const materialSubCategoryChangeHandler = (ev) => {
        setMaterialSubCategoryId(ev);
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
        getDetails(ev.value);
    }
    useEffect(() => {
        setLoading(true);
        getProductCategories();
        getMaterialCategories();
        setLoading(false);
    }, []);

    const quantityChangeHandler = (event, index) => {
        let updatedObject = quantity[index];
        updatedObject = event.target.value;
        quantity[index] = updatedObject;
        setQuantity([...quantity]);
    };

    const handleCheckboxChange = (checked, index) => {
        let updatedObject = isSelected[index];
        updatedObject = checked;
        isSelected[index] = updatedObject;
        setIsSelected([...isSelected]);
    }

    const clearHandler = () => {
        setProductCatId(null);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setMaterialCategoryId(null);
        setMaterialSubCategoryId(null);
        setData([]);
        setMaterialIds([]);
        setIsSelected([]);
        setErrors('');
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Sub Assembly/Part Material Mapping
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align="right" style={{ fontSize: '12px', width: '15%' }}>Product Category :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                options={productCatData}
                                                name="productCategory"
                                                value={productCatId}
                                                onChange={productCategoryChangeHandler}
                                                placeholder="Select Product Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productCategory"]}</div>
                                        </TableCell>
                                        <TableCell align="right" style={{ fontSize: '12px', width: '15%' }}>Product Sub Category :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                options={productSubCatData}
                                                name="productSubCategory"
                                                value={productSubCatId}
                                                onChange={productSubCategoryChangeHandler}
                                                placeholder="Select Product Sub Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productSubcategory"]}</div>
                                        </TableCell>
                                        <TableCell align="right" style={{ fontSize: '12px', width: '10%' }}>Product :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                options={productData}
                                                name="product"
                                                value={productId}
                                                onChange={productChangeHandler}
                                                placeholder="Select Product"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".product"]}</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" style={{ fontSize: '12px' }}>Sub Assembly :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={subAssemblyData}
                                                name="subassembly"
                                                value={subAssemblyId}
                                                onChange={subAssemblyChangeHandler}
                                                placeholder="Select Sub Assembly"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".subAssemblyId"]}</div>
                                        </TableCell>
                                        <TableCell align="right" style={{ fontSize: '12px' }}>Part :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={partData}
                                                name="part"
                                                value={partId}
                                                onChange={partChangeHandler}
                                                placeholder="Select Part"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" style={{ fontSize: '12px' }}>Material Category :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={materialCategoryData}
                                                name="materialCategory"
                                                value={materialCategoryId}
                                                onChange={materialCategoryChangeHandler}
                                                placeholder="Select Material Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialCategoryId"]}</div>
                                        </TableCell>
                                        <TableCell align="right" style={{ fontSize: '12px' }}>Material Sub Category :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={materialSubCategoryData}
                                                name="materialSubCategory"
                                                value={materialSubCategoryId}
                                                onChange={materialSubCategoryChangeHandler}
                                                placeholder="Select Material Sub Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialSubCategoryId"]}</div>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material Sub Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Quantity</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row, index) => (
                                                    <TableRow
                                                        key={row.materialId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            <Checkbox checked={isSelected[index] || false}
                                                                onChange={(e) => handleCheckboxChange(e.target.checked, index)} id="chkSelect" />
                                                        </TableCell>
                                                        <TableCell>{row.materialCategory}</TableCell>
                                                        <TableCell>{row.materialSubCategory}</TableCell>
                                                        <TableCell>{row.material}</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Quantity" fullWidth value={quantity[index] || ''}
                                                                name="quantity"
                                                                onChange={ev => quantityChangeHandler(ev, index)}
                                                                id="quantity"
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".quantity"]}</div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialId"]}</div>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD'
                                                || userDesignation === 'Bussiness Development and Operations' || userDesignation === 'Asst Mgr - Production' ?
                                                <MDButton color="info" onClick={submitHandler}>
                                                    SAVE
                                                </MDButton>
                                                : null}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                Clear
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default SubAssemblyMaterialMap;