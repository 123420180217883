import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import 'react-dropdown/style.css';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import * as service from '../../services/service';

function MaterialStockReport() {
    const [reportDetailsData, setReportDetailsData] = useState([]);
    const [showDetailsReport, setShowDetailsReport] = useState(false);
    const [materialCategoryData, setMaterialCategoryData] = useState([]);
    const [materialSubCategoryData, setMaterialSubCategoryData] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [materialCategoryId, setMaterialCategoryId] = useState('');
    const [materialSubCategoryId, setMaterialSubCategoryId] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getMaterialCategories = () => {
        fetch(
            service.MATERIAL_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialCategoryId, label: item.materialCategory }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setMaterialCategoryData(data2);
                setMaterialCategoryId(all);
            });
    };
    const getMaterialSubCategories = (id) => {
        fetch(
            service.MATERIAL_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    materialCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialSubCategoryId, label: item.materialSubCategory }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setMaterialSubCategoryData(data2);
                setMaterialSubCategoryId(all);
            });
    };
    const getMaterials = (id) => {
        fetch(
            service.MATERIAL_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    materialCategoryId: materialCategoryId.value,
                    materialSubCategoryId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialId, label: item.material }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setMaterialData(data2);
                setMaterialId(all);
            });
    };

    function GetReport() {
        const errs = {};
        fetch(
            service.GET_MATERIAL_STOCK_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MaterialCategoryId: materialCategoryId ? materialCategoryId.value : 0,
                    MaterialSubCategoryId: materialSubCategoryId ? materialSubCategoryId.value : 0,
                    MaterialId: materialId ? materialId.value : 0
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setReportDetailsData([]);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setReportDetailsData([]);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setReportDetailsData([]);
                }
            });
    }

    useEffect(() => {
        setLoading(true);
        getMaterialCategories();
        setLoading(false);
    }, []);

    const materialCategoryChangeHandler = (ev) => {
        setMaterialCategoryId(ev);
        setMaterialSubCategoryId(null);
        setMaterialId(null);
        getMaterialSubCategories(ev.value);
        setReportDetailsData([]);
    }
    const materialSubCategoryChangeHandler = (ev) => {
        setMaterialSubCategoryId(ev);
        setMaterialId(null);
        getMaterials(ev.value);
        setReportDetailsData([]);
    }
    const materialChangeHandler = (ev) => {
        setMaterialId(ev);
        setReportDetailsData([]);
    }

    const submitHandler = e => {
        setShowDetailsReport(false);
        e.preventDefault();
        setLoading(true);
        GetReport();
        setLoading(false);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Store'
                            || userDesignation === 'Jr Mgr - Store' || userDesignation === 'Manager - Store'
                            || userDesignation === 'Sr Mgr - Store' || userDesignation === 'MD'
                            || userDesignation === 'Bussiness Development and Operations' || userDesignation === 'Assistant - Store' ?
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Material Stock Report
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell width="15%" align="right" style={{ fontSize: '12px' }}>
                                            Material Category :
                                        </TableCell>
                                        <TableCell width="20%" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={materialCategoryData}
                                                name="materialCategory"
                                                value={materialCategoryId}
                                                onChange={materialCategoryChangeHandler}
                                                placeholder="Select Material Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell width="15%" align="right" style={{ fontSize: '12px' }}>
                                            Material Sub Category :
                                        </TableCell>
                                        <TableCell width="20%" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={materialSubCategoryData}
                                                name="materialSubCategory"
                                                value={materialSubCategoryId}
                                                onChange={materialSubCategoryChangeHandler}
                                                placeholder="Select Material Sub Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell width="10%" align="right" style={{ fontSize: '12px' }}>
                                            Material :
                                        </TableCell>
                                        <TableCell style={{ fontSize: '12px' }}>
                                            <Select
                                                options={materialData}
                                                name="material"
                                                value={materialId}
                                                onChange={materialChangeHandler}
                                                placeholder="Select Material"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="6" align="center">
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ?
                                                    <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    />

                                                    :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        Show
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <Card style={{ alignItems: 'center' }}>
                                    {loading ?
                                        <Oval
                                            heigth="100"
                                            width="100"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                        <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                            <Table id="dvDetailReport">
                                                <TableRow>
                                                    <TableCell colSpan="9" align="center">
                                                        <Table>
                                                            <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                Category : {materialCategoryId ? materialCategoryId.label : 'All'}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                Sub Category : {materialSubCategoryId ? materialSubCategoryId.label : 'All'}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                Material : {materialId ? materialId.label : 'All'}
                                                            </TableCell>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Material Category</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Material Sub Category</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Material</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Material Code</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Unit</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Reorder Quantity</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Received Quantity</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Issued Quantity</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Available Quantity</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Store Location</TableCell>                                                    
                                                </TableRow>
                                                {reportDetailsData.map((row) => (
                                                    <TableRow
                                                        key={row.materialId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell style={{ fontSize: '14px' }}>{row.materialCategory}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.materialSubCategory}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.material}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.materialCode}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.unitOfMeasure}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.reorderQuantity}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.receivedQuantity}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.issuedQuantity}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.availableQuantity}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.storeLocation}</TableCell>                                                        
                                                    </TableRow>
                                                ))}
                                            </Table>
                                            <br />
                                            <Table>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {showDetailsReport ?
                                                            <ReactHTMLTableToExcel table="dvDetailReport" className="info"
                                                                filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                            />
                                                            : null}
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                            </Card>
                            :
                            <Card style={{ alignItems: 'center' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '14px', color: 'red' }}>You do not have access to this page</div>
                            </Card>
                        }
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default MaterialStockReport;
