import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import * as moment from 'moment';
import * as service from '../../services/service';

function WorkAllocationToSupplier() {
    const [data, setData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();
    const getSuppliers = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                setSupplierData(data2);
            });
    };
    const getDetails = (id) => {
        if (id > 0) {
            fetch(
                service.WORK_ALLOCATION_TO_SUPPLIER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SupplierId: id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                })
                .catch(err => {
                    if (err) {
                        setError(err);
                    };
                });
        }
    };


    const supplierChangeHandler = (ev) => {
        setSupplierId(ev);
        getDetails(ev.value);
    }

    useEffect(() => {
        setLoading(true);
        getSuppliers();
        if (JSON.parse(localStorage.getItem('supplierId')) != null) {

            const cat = ({ value: JSON.parse(localStorage.getItem('supplierId')), label: JSON.parse(localStorage.getItem('supplierName')) });
            setSupplierId(cat);
            getDetails(JSON.parse(localStorage.getItem('supplierId')));
        };
        localStorage.setItem('supplierId', null);
        localStorage.setItem('supplierName', null);
        setLoading(false);
    }, []);

    // const handleEdit = (id) => {
    //     navigate(`/addeditworkallocationtosupplier/${id}`);
    // }
    const addNew = () => {
        navigate('/addeditworkallocationtosupplier/0');
    };

    const handleDelete = (id) => {
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Do you want to delete this details?")) {
            fetch(
                service.DELETE_WORK_ALLOCATION_TO_SUPPLIER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkAllocationId: id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
            setData(null);
            window.location.reload(false);
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                || userDesignation === 'Jr Mgr - Production' || userDesignation === 'Asst Mgr - Production'
                || userDesignation === 'Bussiness Development and Operations' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Work Allocation To Supplier
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '30%' }}>Supplier :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                options={supplierData}
                                                name="supplier"
                                                value={supplierId}
                                                onChange={supplierChangeHandler}
                                                placeholder="Select Supplier"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allocation For</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Job No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Operation</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allocated Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Planned Completion Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                    || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                                                    || userDesignation === 'Jr Mgr - Production' || userDesignation === 'Asst Mgr - Production'
                                                    || userDesignation === 'Bussiness Development and Operations' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.workAllocationId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.allocationFor}</TableCell>
                                                        <TableCell>{row.customerName}</TableCell>
                                                        <TableCell>{row.jobNo}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.subAssembly}</TableCell>
                                                        <TableCell>{row.part}</TableCell>
                                                        <TableCell>{row.operation}</TableCell>
                                                        <TableCell>{moment(row.allocationDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{moment(row.plannedCompletionDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{row.remarks}</TableCell>
                                                        <TableCell>{row.status}</TableCell>
                                                        {/* <TableCell>
                                                            {row.status === 'Pending' ?
                                                                <MDButton onClick={() => handleEdit(row.workAllocationId)}>Edit</MDButton>
                                                                : null}
                                                        </TableCell> */}
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                            || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                                                            || userDesignation === 'Jr Mgr - Production' || userDesignation === 'Asst Mgr - Production'
                                                            || userDesignation === 'Bussiness Development and Operations' ?
                                                            <TableCell>
                                                                {row.status === 'Pending' ?
                                                                    <MDButton onClick={() => handleDelete(row.workAllocationId)}>Delete</MDButton>
                                                                    : null}
                                                            </TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default WorkAllocationToSupplier;