// import { useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';
import { Checkbox, Radio } from '@mui/material';
import Select from 'react-select';
import * as service from '../../services/service';

function StockMappingForWorOrder() {
    const [customerData, setCustomerData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [stockData, setStockData] = useState([]);
    const [selectedStock, setSelectedStock] = useState([]);
    const [productData, setProductData] = useState([]);
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [parentStockId, setParentStockId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [mappingFor, setMappingFor] = useState('');
    const mappingForData = [{ label: 'Product', value: 'Product' }, { label: 'Sub Assembly', value: 'Sub Assembly' }]
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };

    const getWorkOrders = (id) => {
        fetch(
            service.GET_PENDING_WORK_ORDER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CustomerId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workOrderId, label: item.jobNo }));
                setWorkOrderData(data2);
            });
    };

    const getProducts = (id) => {
        fetch(
            service.GET_COMPLETED_PRODUCTS_FOR_MAPPING,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setProductData(result);
            });
    };

    const getSubAssemblies = (id) => {
        fetch(
            service.GET_COMPLETED_SUBASSEMBLY_FOR_MAPPING,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setSubAssemblyData(result);
            });
    };

    const getAvailbleStock = (prId, subId) => {
        const errs = {};
        fetch(
            service.GET_COMPLETED_STOCK_FOR_MAPPING,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: workOrderId.value,
                    ProductId: prId,
                    SubAssemblyId: subId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Success") {
                    setStockData(result);
                    setErrors({});
                    setDisable(false);
                }
                else if (result[0].message.messageType === "Insufficient") {
                    setStockData(result);
                    errs[".nodata"] = result[0].message.message;
                    setErrors(errs);
                    setDisable(true);
                }
                else {
                    setStockData([]);
                    errs[".nodata"] = result[0].message.message;
                    setErrors(errs);
                    setDisable(true);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        setLoading(true);
        getCustomers();
        setLoading(false);
    });

    const mappingForChangeHandler = (ev) => {
        setMappingFor(ev);
        setCustomerId(null);
        setWorkOrderId(null);
        setErrors('');
        setStockData([]);
    }

    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
        setWorkOrderId(null);
        setErrors('');
        setStockData([]);
        getWorkOrders(ev.value);
    }
    const workOrderChangeHandler = (ev) => {
        setWorkOrderId(ev);
        setErrors('');
        setStockData([]);
        if (mappingFor.value === "Product") {
            getProducts(ev.value);
        }
        else {
            getSubAssemblies(ev.value);
        }
    }

    const handleProductRadioChange = (stId, prId) => {
        setParentStockId(stId);
        getAvailbleStock(prId, 0);
    }

    const handleSubAssemblyRadioChange = (stId, prId, saId) => {
        setParentStockId(stId);
        getAvailbleStock(prId, saId);
    }

    const handleCheckboxChange = (checked, id) => {
        if (checked) {
            setSelectedStock([...selectedStock, id]);
        }
        else {
            setSelectedStock(selectedStock.filter((item) => (item !== id)));
        }
    }

    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        let flag = true;
        // eslint-disable-next-line
        if (window.confirm("Do you want to save this stock allocation? You won't be able to change it later...")) {
            selectedStock.forEach((element) => {
                fetch(
                    service.ADD_STOCK_MAPPING,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ParentStockId: parentStockId,
                            StockId: element,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId
                        })
                    })
                    .then(resp => resp.json())
                    .then(result => {
                        if (result.messageType === 'Failed') {
                            flag = false;
                            // eslint-disable-next-line
                            alert(result.message);
                        }
                    });
            });
            if (flag) {
                // eslint-disable-next-line
                alert("Data Saved Successfully");
                // navigate('/masters/operationmachinemap');
                window.location.reload(false);
            }
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Stock Mapping For Work Order (Completed Stock)
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <div>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                        Important Note
                                                        <br />
                                                        1. Select Mapping For Sub Assembly.
                                                        <br />
                                                        List of Sub Assemblies which are not yet mapped will get displayed below.
                                                        Select a sub assembly for mapping. Completed Parts will be displayed below.
                                                        <br />
                                                        If there are insufficient numbers of parts, the message will get displayed.
                                                        You can not save the mapping if parts are not available in required quantity.
                                                        <br />
                                                        Else select required number of Parts and click on Save to save the mapping.
                                                        <br />
                                                        <br />
                                                        2. Select Mapping For Product.
                                                        <br />
                                                        List of Products which are not yet mapped will get displayed below.
                                                        Select a product for mapping. Completed Sub Assemblies will be displayed below.
                                                        <br />
                                                        If there are insufficient numbers of sub assemblies, the message will get displayed.
                                                        You can not save the mapping if sub assemblies are not available in required quantity.
                                                        <br />
                                                        Else select required number of Sub Assemblies and click on Save to save the mapping.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <div style={{ fontSize: '12px' }}>
                                                            <Select
                                                                options={mappingForData}
                                                                name="mappingFor"
                                                                value={mappingFor}
                                                                onChange={mappingForChangeHandler}
                                                                placeholder="Select Mapping For"
                                                                isSearchable
                                                                styles={selectStyles}
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mappingFor"]}</div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                            <Select
                                                                name="customer"
                                                                options={customerData}
                                                                placeholder="Select Customer"
                                                                value={customerId}
                                                                onChange={customerChangeHandler}
                                                                isSearchable
                                                                styles={selectStyles}
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customer"]}</div>
                                                        </MDBox>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                            <Select
                                                                name="workorder"
                                                                options={workOrderData}
                                                                placeholder="Select Job Number"
                                                                value={workOrderId}
                                                                onChange={workOrderChangeHandler}
                                                                isSearchable
                                                                styles={selectStyles}
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workorder"]}</div>
                                                        </MDBox>
                                                    </TableCell>
                                                </TableRow>
                                                {mappingFor.value === 'Product' && productData ?
                                                    <TableRow>
                                                        <TableCell colSpan={2} align='center'>
                                                            {loading ? <Oval
                                                                heigth="100"
                                                                width="100"
                                                                color='grey'
                                                                ariaLabel='loading'
                                                            /> :
                                                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                                                    <Table aria-label="simple table">
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                                        </TableRow>

                                                                        <TableBody>
                                                                            {productData.map((row) => (
                                                                                <TableRow
                                                                                    key={row.stockId}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell><Radio onChange={() => handleProductRadioChange(row.stockId, row.productId)}
                                                                                        value={row.stockId} name="radio-buttons"
                                                                                        checked={parentStockId === row.stockId} />
                                                                                    </TableCell>
                                                                                    <TableCell>{row.product}</TableCell>
                                                                                    <TableCell>{row.stockNumber}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stockId"]}</div>
                                                                </TableContainer>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    : null}
                                                {mappingFor.value === 'Sub Assembly' && subAssemblyData ?
                                                    <TableRow>
                                                        <TableCell colSpan={2} align='center'>
                                                            {loading ? <Oval
                                                                heigth="100"
                                                                width="100"
                                                                color='grey'
                                                                ariaLabel='loading'
                                                            /> :
                                                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                                                    <Table aria-label="simple table">
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                                        </TableRow>

                                                                        <TableBody>
                                                                            {subAssemblyData.map((row) => (
                                                                                <TableRow
                                                                                    key={row.stockId}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell><Radio onChange={() => handleSubAssemblyRadioChange(row.stockId, row.productId, row.subAssemblyId)}
                                                                                        value={row.stockId} name="radio-buttons"
                                                                                        checked={parentStockId === row.stockId} />
                                                                                    </TableCell>
                                                                                    <TableCell>{row.product}</TableCell>
                                                                                    <TableCell>{row.subAssembly}</TableCell>
                                                                                    <TableCell>{row.stockNumber}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stockId"]}</div>
                                                                </TableContainer>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    : null}
                                            </TableBody>
                                        </Table>
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        {mappingFor.value === 'Product' ?
                                            <Table aria-label="simple table">
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {stockData.map((row) => (
                                                        <TableRow
                                                            key={row.stockId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>
                                                                <Checkbox onChange={(e) => handleCheckboxChange(e.target.checked, row.stockId)} id="chkSelect" />
                                                            </TableCell>
                                                            <TableCell>{row.product}</TableCell>
                                                            <TableCell>{row.subAssembly}</TableCell>
                                                            <TableCell>{row.stockNumber}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            : null}
                                        {mappingFor.value === 'Sub Assembly' ?
                                            <Table aria-label="simple table">
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {stockData.map((row) => (
                                                        <TableRow
                                                            key={row.stockId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>
                                                                <Checkbox onChange={(e) => handleCheckboxChange(e.target.checked, row.stockId)} id="chkSelect" />
                                                            </TableCell>
                                                            <TableCell>{row.product}</TableCell>
                                                            <TableCell>{row.subAssembly}</TableCell>
                                                            <TableCell>{row.part}</TableCell>
                                                            <TableCell>{row.stockNumber}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            : null}
                                    </div>
                                }
                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".nodata"]}</div> : null}
                                {stockData.length > 0 && (userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                    || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' || userDesignation === 'Bussiness Development and Operations') ?
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        {loading ? <Oval
                                            heigth="20"
                                            width="20"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :

                                            <MDButton color="info" onClick={submitHandler} disabled={disable}>
                                                Save Stock
                                            </MDButton>
                                        }
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                    </MDBox>
                                    : null}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default StockMappingForWorOrder;