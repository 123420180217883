// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import { Radio } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import * as service from '../../services/service';

function PreDeliveryInspection() {
    const [data, setData] = useState([]);
    const [inspectionData, setInspectionData] = useState([]);
    const [remarksData, setRemarksData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [productId, setProductId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [jobNo, setJobNo] = useState('');
    const [stockId, setStockId] = useState('');
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getDetails = () => {
        fetch(
            service.GET_PRODUCTS_FOR_PRE_DELIVERY_INSPECTION,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const getInspectionParameters = (id) => {
        fetch(
            service.PRE_DELIVERY_INSPECTION_CRITERIA_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setInspectionData(result);
                result.forEach((index) => {
                    remarksData[index] = '';
                    setRemarksData([...remarksData]);
                    statusData[index] = { lable: 'Approved', value: 'Approved' };
                    setStatusData([...statusData]);
                });
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const handleRadioChange = (id, workId, jNo, stId) => {
        setProductId(id);
        setWorkOrderId(workId);
        setJobNo(jNo);
        setStockId(stId);
        getInspectionParameters(id);
    }

    useEffect(() => {
        setLoading(true);
        getDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!productId) {
            isValid = false;
            errs[".productId"] = "Please select product";
        }
        setError(errs);
        return isValid;
    }
    const remarksChangeHandler = (event, index) => {
        let updatedObject = remarksData[index];
        updatedObject = event.target.value;
        remarksData[index] = updatedObject;
        setRemarksData([...remarksData]);
    };

    const statusChangeHandler = (index, status) => {
        let updatedObject = statusData[index];
        updatedObject = status;
        statusData[index] = updatedObject;
        setStatusData([...statusData]);
    };
    // Save inspection 
    const SavePreDeliveryInspection = () => {
        let flag = true;
        if (inspectionData) {
            inspectionData.forEach((element, index) => {
                fetch(
                    service.ADD_PRE_DELIVERY_INSPECTION,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ProductId: productId,
                            WorkOrderId: workOrderId,
                            JobNo: jobNo,
                            StockId: stockId,
                            CriteriaId: element.criteriaId,
                            Remarks: remarksData[index],
                            Status: statusData[index],
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res1 => res1.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            flag = true;
                        }
                        else if (result.messageType === 'Failed') {
                            flag = false;
                        }
                        else {
                            flag = false;
                        }
                    });
            });
        }
        if (flag) {
            // eslint-disable-next-line
            alert("Data Saved Successfully");
            setProductId('');
            setWorkOrderId('');
            setJobNo('');
            setInspectionData([]);
            setRemarksData([]);
            setStatusData([]);
            window.location.reload(false);
        }
        else {
            // eslint-disable-next-line
            alert("Error.. Something Went Wrong");
        }
    }

    // Save daily work status
    const submitHandler = (e) => {
        e.preventDefault();
        // const errs = {};
        setLoading(true);
        if (validate()) {
            let tskSt = true;
            statusData.forEach((element) => {
                if (element === "Rejected") {
                    tskSt = false;
                }
            });
            // eslint-disable-next-line
            if (window.confirm(`Do you want to save this task as ${tskSt ? 'Approved' : 'Rejected'}?`)) {
                fetch(
                    service.ADD_PRE_DELIVERY_INSPECTION_RESULT,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ProductId: productId,
                            WorkOrderId: workOrderId,
                            JobNo: jobNo,
                            StockId: stockId,
                            Status: tskSt ? "Approved" : "Rejected",
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            SavePreDeliveryInspection();
                        }
                        else if (result.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message);
                        }
                    });
            }
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Products For Pre Delivery Inspection
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Work Order No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Job No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Stock No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.stockId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell><Radio onChange={() => handleRadioChange(row.productId, row.workOrderId, row.jobNo, row.stockId)}
                                                            value={row.stockId} name="radio-buttons"
                                                            checked={stockId === row.stockId} />
                                                        </TableCell>
                                                        <TableCell>{row.customerName}</TableCell>
                                                        <TableCell>{row.workOrderNo}</TableCell>
                                                        <TableCell>{row.jobNo}</TableCell>
                                                        <TableCell>{row.stockNo}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <div style={{ fontSize: '12px', color: 'red' }}>{error[".productId"]}</div>
                                    </TableContainer>
                                }
                            </Card>
                            <br />
                            <MDTypography variant="h6" color="white">
                                Pre Delivery Inspection Criteria
                            </MDTypography>
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :

                                    <Table aria-label="simple table" width="70%">
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }} width="35%">Criteria</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} colSpan="2" align="center">Status</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                        </TableRow>
                                        <TableBody>
                                            {inspectionData.map((row, index) => (
                                                <TableRow
                                                    key={row.criteriaId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{row.criteria}</TableCell>
                                                    <TableCell>
                                                        <MDButton onClick={() => statusChangeHandler(index, 'Approved')} disabled={statusData[index] === "Approved"}>Approved</MDButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDButton onClick={() => statusChangeHandler(index, 'Rejected')} disabled={statusData[index] === "Rejected"}>Rejected</MDButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Remarks" fullWidth
                                                            nasme="remarks"
                                                            onChange={ev => remarksChangeHandler(ev, index)}
                                                            id="remarks"
                                                            multiline
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                                                || userDesignation === 'Jr Mgr - Production' || userDesignation === 'Asst Mgr - Production'
                                                || userDesignation === 'Bussiness Development and Operations' || userDesignation === 'Jr Inspector' ?
                                                <TableRow>
                                                    <TableCell colSpan='4' align="center">
                                                        <MDBox mt={4} mb={1} textAlign="center">
                                                            {loading ? <Oval
                                                                heigth="20"
                                                                width="20"
                                                                color='grey'
                                                                ariaLabel='loading'
                                                            /> :
                                                                <MDButton color="info" onClick={submitHandler}>
                                                                    Save
                                                                </MDButton>
                                                            }
                                                            {error ? <div style={{ fontSize: '12px', color: 'red' }}>{error[".invalid"]}</div> : null}
                                                        </MDBox>
                                                    </TableCell>
                                                </TableRow>
                                                : null}
                                        </TableBody>

                                    </Table>

                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default PreDeliveryInspection;