
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
// import { useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Radio } from '@mui/material';
import * as service from '../../../services/service';

function AddEditWorkAllocationForWorkOrder() {
    const [state, setState] = useState({ remarks: '' });
    const [allocationDate, setAllocationDate] = useState(new Date());
    const [plannedCompletionDate, setPlannedCompletionDate] = useState(new Date());
    const [allocationData, setAllocationData] = useState([]);
    const [workAllocationData, setWorkAllocationData] = useState([]);
    // const [allWorkOrderData, setAllWorkOrderData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [operationData, setOperationData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [operationId, setOperationId] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [stockId, setStockId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };

    const getWorkOrders = (id) => {
        fetch(
            service.GET_PENDING_WORK_ORDER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CustomerId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workOrderId, label: item.jobNo }));
                setWorkOrderData(data2);
            });
    };

    const getStock = (id) => {
        const errs = {};
        fetch(
            service.GET_REQUIRED_STOCK,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Success") {
                    setAllocationData(result);
                }
                else {
                    errs[".noreqdata"] = result[0].message.message;
                    setErrors(errs);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const getOperations = (prId, assemblyId, prtId) => {
        fetch(
            service.OPERATION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prId,
                    SubAssemblyId: assemblyId,
                    PartId: prtId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.operationId, label: item.operation }));
                setOperationData(data2);
            });
    };

    const getEmployees = () => {
        fetch(
            service.EMPLOYEE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.employeeId, label: item.employeeName }));
                setEmployeeData(data2);
            });
    };

    const getWorkAllocation = (stId, prId, subId, ptId) => {
        fetch(
            service.GET_WORK_ALLOCATION_BY_PART_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: workOrderId.value,
                    JobNo: workOrderId.label,
                    StockId: stId,
                    ProductId: prId,
                    SubAssemblyId: subId,
                    PartId: ptId
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === "Success") {
                    setWorkAllocationData(result);
                }
                else {
                    setWorkAllocationData(null);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };
    useEffect(() => {
        getCustomers();
        getEmployees();
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!customerId) {
            isValid = false;
            errs[".customer"] = "Please select customer";
        }
        if (!workOrderId) {
            isValid = false;
            errs[".workorder"] = "Please select work order";
        }
        if (!stockId) {
            isValid = false;
            errs[".stockId"] = "Please select stock";
        }
        if (!operationId) {
            isValid = false;
            errs[".operation"] = "Please select operation";
        }
        if (!employeeId) {
            isValid = false;
            errs[".employee"] = "Please select employee";
        }
        if (!allocationDate) {
            isValid = false;
            errs[".allocationdate"] = "Please enter allocation date";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const handleRadioChange = (stId, prId, subId, ptId) => {
        setStockId(stId);
        setProductId(prId);
        setSubAssemblyId(subId);
        setPartId(ptId);
        setOperationId(null);
        getOperations(prId, subId, ptId);
        setWorkAllocationData(null);
        getWorkAllocation(stId, prId, subId, ptId);
    }
    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
        setWorkOrderId(null);
        setOperationId(null);
        setStockId(null);
        setAllocationData([]);
        getWorkOrders(ev.value);
    }
    const workOrderChangeHandler = (ev) => {
        setWorkOrderId(ev);
        setOperationId(null);
        setStockId(null);
        setAllocationData([]);
        getStock(ev.value);
    }
    const employeeChangeHandler = (ev) => {
        setEmployeeId(ev);
    }
    const operationChangeHandler = (ev) => {
        setOperationId(ev);
    }

    const handleDelete = (id) => {
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Do you want to delete this details?")) {
            fetch(
                service.DELETE_WORK_ALLOCATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkAllocationId: id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
            setWorkAllocationData(null);
            window.location.reload(false);
        }
        setLoading(false);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_WORK_ALLOCATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkAllocationId: 0,
                        Remarks: state.remarks,
                        CustomerId: customerId.value,
                        WorkOrderId: workOrderId.value,
                        JobNo: workOrderId.label,
                        StockId: stockId,
                        ProductId: productId,
                        SubAssemblyId: subAssemblyId,
                        PartId: partId,
                        OperationId: operationId.value,
                        EmployeeId: employeeId.value,
                        AllocationDate: moment(allocationDate).format("MM/DD/YYYY"),
                        PlannedCompletionDate: moment(plannedCompletionDate).format("MM/DD/YYYY"),
                        AllocationFor: 'WorkOrder',
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload(false);
                        // navigate('/workallocation/workallocation');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/workallocation/workallocation');
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="customer"
                                                            options={customerData} placeholder="Select Customer"
                                                            value={customerId}
                                                            onChange={customerChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customer"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="workorder"
                                                            options={workOrderData} placeholder="Select Job Number"
                                                            value={workOrderId}
                                                            onChange={workOrderChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workorder"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2} align='center'>
                                                    {loading ? <Oval
                                                        heigth="100"
                                                        width="100"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                                            <Table aria-label="simple table">
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                                </TableRow>

                                                                <TableBody>
                                                                    {allocationData.map((row) => (
                                                                        <TableRow
                                                                            key={row.stockId}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell><Radio onChange={() => handleRadioChange(row.stockId, row.productId, row.subAssemblyId, row.partId)}
                                                                                value={row.stockId} name="radio-buttons"
                                                                                checked={stockId === row.stockId} />
                                                                            </TableCell>
                                                                            <TableCell>{row.product}</TableCell>
                                                                            <TableCell>{row.subAssembly}</TableCell>
                                                                            <TableCell>{row.part}</TableCell>
                                                                            <TableCell>{row.stockNumber}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stockId"]}</div>
                                                        </TableContainer>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="operation"
                                                            options={operationData} placeholder="Select Operation"
                                                            value={operationId}
                                                            onChange={operationChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".operation"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="employee"
                                                            options={employeeData} placeholder="Select Employee"
                                                            value={employeeId}
                                                            onChange={employeeChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".employee"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Allocation Date
                                                    <DatePicker
                                                        id="allocationDate"
                                                        selected={allocationDate}
                                                        onChange={date => setAllocationDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    Planned Completion Date
                                                    <DatePicker
                                                        id="plannedCompletionDate"
                                                        selected={plannedCompletionDate}
                                                        onChange={date => setPlannedCompletionDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                        name="remarks"
                                                        onChange={changeHandler}
                                                        id="remarks"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                    || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                                    || userDesignation === 'Bussiness Development and Operations' ?
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        {loading ? <Oval
                                            heigth="20"
                                            width="20"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>
                                        }
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                    </MDBox>
                                    : null}
                            </Card>
                            {workAllocationData && workAllocationData.workAllocationId ?
                                <Card style={{ alignItems: 'center' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', width: '100%', backgroundColor: 'lightgray' }} align="center">
                                        Work Allocation Details
                                    </div>
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Employee</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allocation Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Planned Completion Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                    || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD'
                                                    || userDesignation === 'Bussiness Development and Operations' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {/* {workAllocationData.workAllocationId ?  */}
                                                <TableRow
                                                    key={workAllocationData.workAllocationId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{workAllocationData.employeeName}</TableCell>
                                                    <TableCell>{moment(workAllocationData.allocationDate).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell>{moment(workAllocationData.plannedCompletionDate).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell>{workAllocationData.remarks}</TableCell>
                                                    {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                        || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD'
                                                        || userDesignation === 'Bussiness Development and Operations' ?
                                                        <TableCell><MDButton onClick={() => handleDelete(workAllocationData.workAllocationId)}>Delete</MDButton></TableCell>
                                                        : null}
                                                </TableRow>
                                                {/* : null } */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                                : null}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditWorkAllocationForWorkOrder;